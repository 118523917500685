/* eslint-disable indent */
import React from 'react';
import ReactDOM from 'react-dom';
import { LOGIN_PATH } from '@utils/constants';
import i18n, { LANG_KAY } from '@utils/i18n';
import { removeToken, removeUserName } from './auth';
import store from '@/store';

function createModal(props, Comp) {
    const div = document.createElement('div');
    document.body.append(div);

    function onCancel() {
        const ret = ReactDOM.unmountComponentAtNode(div);

        if (ret && div.parentNode) {
            div.parentNode.removeChild(div);
        }
    }

    ReactDOM.render(<Comp {...props} onCancel={onCancel} />, div);
}

function gotoLogin() {
    removeToken();
    removeUserName();
    const state = store.getGlobalState();
    const sourceType = state.user?.sourceType;

    window.location.href =
        window.location.origin +
        LOGIN_PATH +
        `?redirect=${encodeURIComponent(window.location.href)}${
            !state.isWhaleCustomer && !state.isHongCustomer && sourceType === 8 ? `&sourceType=${sourceType}` : ''
        }`;
}

function copyToClipboard(text) {
    // 创建一个临时的textarea元素
    const textarea = document.createElement('textarea');
    textarea.style.position = 'absolute';
    textarea.style.top = '-9999px';
    textarea.style.left = '9999px';
    textarea.value = text;

    // 将textarea元素添加到DOM中
    document.body.appendChild(textarea);

    // 选择并复制textarea中的文本
    textarea.select();
    document.execCommand('copy');

    // 移除临时的textarea元素
    document.body.removeChild(textarea);
}

function parseObject(str) {
    let val = null;

    try {
        val = JSON.parse(str);
    } catch (e) {
        //
    }

    return val;
}

function setPageTitle(title) {
    const state = store.getGlobalState();
    const pre = state.isHongCustomer
        ? i18n.get(LANG_KAY.MENU_TITLE_SYSTEM_NAME1)
        : state.user?.sourceType === 8 || state.isWhaleCustomer
        ? i18n.get(LANG_KAY.MENU_TITLE_SYSTEM_NAME2)
        : i18n.get(LANG_KAY.MENU_TITLE_SYSTEM_NAME3);

    if (title) {
        document.title = `${pre} - ${title}`;
    } else {
        document.title = pre;
    }
}

function openUrl(url, target = '_blank') {
    if (url) {
        const opener = window.open(url, target, 'noopener,noreferrer');

        if (opener) {
            opener.opener = null;
        }
    }
}

function isWhaleCustomer() {
    return window.location.host === 'www.tiktalents.com';
}

function isHongCustomer() {
    return window.location.host === 'tk.tuoyunai.com';
}

function onClickDoc() {
    const state = store.getGlobalState();

    openUrl(
        state.isHongCustomer
            ? 'https://dcncfdg3yrij.feishu.cn/docx/IbR1dkGKSoIEsaxakW0cHFYgnIc'
            : state.user?.sourceType === 8 || state.isWhaleCustomer
            ? 'https://dcncfdg3yrij.feishu.cn/docx/BwoXda1t5oeQHPx9ahIcdjFgnrh'
            : ''
    );
}

export {
    createModal,
    gotoLogin,
    copyToClipboard,
    parseObject,
    setPageTitle,
    isWhaleCustomer,
    isHongCustomer,
    openUrl,
    onClickDoc
};
